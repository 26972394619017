import React from 'react';
import {
  FaCheckCircle, FaExclamationTriangle, FaInfoCircle, FaTimesCircle,
} from 'react-icons/fa';
import { toast, Slide } from 'react-toastify';
import { ReactElement } from 'react';
import { TypeOptions } from 'react-toastify';
import { Box, Flex, Text } from 'theme-ui';

interface ToastOptions {
  title?: string;
  message: string | ReactElement;
  type?: TypeOptions;
}

const colorType = [
  {
    type: 'info',
    color: '#54c7ec'
  },
  {
    type: 'success',
    color: '#12968c'
  },
  {
    type: 'warning',
    color: '#f1c40f'
  },
  {
    type: 'error',
    color: '#e74c3c'
  },
]

const Toast = ({ title = '', message = '', type = 'success' }: ToastOptions) => {
  toast((
    <Flex>
      <Box color={colorType.find(col => col.type === type).color} sx={{ alignSelf: 'center' }}>
        {type === 'success' && <FaCheckCircle />}
        {type === 'info' && <FaInfoCircle />}
        {type === 'warning' && <FaExclamationTriangle />}
        {type === 'error' && <FaTimesCircle />}
      </Box>
      <Box m={2} ml={3}>
        {title && <Text sx={{ fontSize: 16, fontWeight: 'bold' }}>{title}</Text>}
        <Box role="alert" mt={2}>{message}</Box>
      </Box>
    </Flex>
  ), {
    transition: Slide,
    type,
    autoClose: 3000,
    icon: false,
    hideProgressBar: true,
  });
};

export default Toast;
